





























































//TODO: Product may not have an image

import { LogLevels } from "@/models/log/interface";
import Product from "@/models/product/interface";
import Vue from "vue";

export default Vue.extend({
  name: "Product",
  props: {
    product: Product,
  },
  data: () => ({
    owned: false,
    error: true,
    loading: false,
  }),
  mounted() {
    this.$store
      .dispatch("user/checkUserOwnsProduct", this.product.id)
      .then((owned) => {
        this.owned = owned;
        this.error = false;
      })
      .catch((error) => {
        this.$store.dispatch("log", { level: LogLevels.ERROR, message: error });
        this.error = true;
      });
  },
  methods: {
    onBuySelected(): void {
      this.loading = true;
      this.$store
        .dispatch("payment/checkoutProduct", "price_1MTOY6BWV5dMUOPeqI5X6xjS")
        .finally(() => {
          this.loading = false;
        });
    },
    onBuySelectedSemestral(): void {
      this.loading = true;
      this.$store
        .dispatch("payment/checkoutProduct", this.product.getSemesterPrice().id)
        .finally(() => {
          this.loading = false;
        });
    },
    onBuySelectedAnual(): void {
      this.loading = true;
      this.$store
        .dispatch("payment/checkoutProduct", this.product.getYearlyPrice().id)
        .finally(() => {
          this.loading = false;
        });
    },

    onManageProductSelected(): void {
      this.loading = true;
      this.$store.dispatch("payment/manageProduct").finally(() => {
        this.loading = false;
      });
    },
  },
});
