















































import * as firebase from "firebase/app";
import UserDetails from "@/components/profile/UserDetails.vue";
// import Statistics from '@/components/profile/Statistics.vue'
import History from "@/components/profile/History.vue";
import Membership from "@/components/profile/Membership.vue";
import Settings from "@/components/profile/Settings.vue";
import CorregirSimulacro from "@/components/profile/CorregirSimulacro.vue";
import ActivarSimulacro from "@/components/profile/ActivarSimulacro.vue";
import ComprobarAccesoSimulacro from "@/components/profile/ComprobarAccesoSimulacro.vue";
import AdministrarTests from "@/components/profile/AdministrarTests.vue";

export default {
  name: "Profile",
  components: {
    UserDetails,
    // Statistics,
    Membership,
    Settings,
    History,
    CorregirSimulacro,
    ActivarSimulacro,
    AdministrarTests,
    ComprobarAccesoSimulacro
  },
  data() {
    return {
      userIsAdmin: null
    };
  },
  created() {
    this.getAdmin();
  },
  methods: {
    getAdmin() {
      firebase
        .firestore()
        .collection("resources")
        .doc("admin-users")
        .get()
        .then(doc => {
          const found = doc.data().list.includes(this.$store.state.user.id);
          this.userIsAdmin = found;
        })
        .catch(error => {
          console.log("Error getting documents: ", error);
        });
    }
  }
};
