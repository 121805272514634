

































// TODO: Guardar un timestamp en el test con la última vez que se ha corregido para dar info al usuario
//       de que el simulacro ya se ha corregido.
import Vue from "vue";
import * as firebase from "firebase/app";
import "firebase/firestore";
export default Vue.extend({
  name: "ActivarSimulacro",
  data: () => ({
    loading: true,
    simulations: null,
    selectedTest: {id: "", name: "Ningún simulacro activo"}
  }),
  created() {
    this.getSimulations().then(ss => {
        this.prepareSimulations(ss).then(sims => {
          this.$store
            .dispatch("tests/getActiveSimulationTestId")
            .then(activeSimulationTestId => {
              if (activeSimulationTestId == null) {
                this.selectedTest = {id: "", name: "Ningún simulacro activo"}
              } else {
                sims.forEach(doc => {
                  if (doc.id == activeSimulationTestId)
                      this.selectedTest = doc;
                })
              }
            })
        })
    }).catch(error => console.error(error)).finally(_ => this.loading = false);
  },
  methods: {
    prepareSimulations(ss) {
      return new Promise( (resolve, reject) => {
      this.simulations = ss.map(function(item) {
        return {
          id: item.id,
          name: item.title,
        }
      });
      this.simulations.unshift({id: "", name: "Ningún simulacro activo"});
      resolve(this.simulations);
    })},
    getSimulations() {
        return new Promise((resolve, reject) => {
          firebase
            .firestore()
            .collection("tests")
            .where("isTimed", "in", [true, false])
            .orderBy("created", "desc")
            .get()
            .then(function(querySnapshot) {
              const result = [];
              querySnapshot.forEach(function(doc) {
                result.push({
                  id: doc.id,
                  ...doc.data()
                });
              });
              resolve(result);
            })
            .catch(error => {
              reject(error);
            });
        });
    },
    updateActiveSimulationTestId() {
      if (this.selectedTest) {
        firebase
          .firestore()
          .collection("resources")
          .doc("simulation")
          .update({
            activeSimulationTestId: this.selectedTest.id
          })
          .then(() => console.log("Changed active simulation to "+this.simulationTest.id))
          .catch(function(error) {
            console.error(error);
          });
      }
    },
  },
});
