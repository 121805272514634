

























import ProductComponent from "@/components/profile/Product.vue";
import { LogLevels } from "@/models/log/interface";
import Product from "@/models/product/interface";
import Vue from "vue";

export default Vue.extend({
  name: "Membership",
  components: { product: ProductComponent },
  data: () => ({
    loading: true
  }),
  mounted() {
    this.loading = true;
    this.$store
      .dispatch("vm/membership/onMounted")
      .catch(error => {
        this.$store.dispatch("log", { level: LogLevels.ERROR, message: error });
      })
      .finally(() => {
        this.loading = false;
      });
  }
});
