


















































































// TODO: Guardar un timestamp en el test con la última vez que se ha corregido para dar info al usuario
//       de que el simulacro ya se ha corregido.
import Vue from "vue";
import * as firebase from "firebase/app";
import "firebase/firestore";
export default Vue.extend({
  name: "AdministrarTests",
  data: () => ({
    loading: false,
    testsData: null,
    search: '',
    testsTable: {
      headers: [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Título',
          value: 'title'
        },
        {
          text: 'Categoría',
          value: 'category'
        },
        {
          text: 'Preguntas',
          value: 'numqs'
        },
        {
          text: 'Explicación',
          value: 'detailed'
        },
        {
          text: 'Subido el',
          value: 'created'
        },
        {
          text: 'Acciones',
          value: 'actions',
          sortable: false
        }
      ]
    }
  }),
  methods: {
    mapTests(tests) {
      return tests.map(test => {
        return {
          title: test.title,
          created: test.created ? test.created.toDate().toISOString().split('T')[0] : null,
          id: test.id,
          numqs: test.questions ? test.questions.length : 0,
          category: test.category,
          detailed: test.detailed
        }
      }
      )
    },
    loadTests() {
      this.loading = true;
      firebase
        .firestore()
        .collection("tests")
        .get()
        .then((querySnapshot) => {
          const result = [];
          querySnapshot.forEach(function(doc) {
            // doc.data() is never undefined for query doc snapshots
            //console.log(doc.id, " => ", doc.data());
            result.push({
              id: doc.id,
              ...doc.data()
            });
          });
          this.testsData = this.mapTests(result);
          this.loading = false;
        })
        .catch(error => {
          console.error(error);
          this.loading = false;
        });
    },
    editItem (item) {
      this.$router.push({name: 'test_input', params: {testIdToEdit: item.id}})
    },
    deleteItem(item) {
      if (confirm('¿Seguro que quieres eliminar el test '+item.title+'? Esta acción es definitiva y no se puede deshacer.')) {
        const index = this.testsData.indexOf(item);
        this.testsData.splice(index, 1);
        firebase
          .firestore()
          .collection("tests").doc(item.id).delete().then(() => {
            alert("El test ha sido eliminado");
        }).catch((error) => {
            alert("Error eliminando el test:"+ error);
        });
      }
    }
  }
});
