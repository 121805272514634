




































































// TODO: Guardar un timestamp en el test con la última vez que se ha corregido para dar info al usuario
//       de que el simulacro ya se ha corregido.
import Vue from "vue";
import * as firebase from "firebase/app";
import "firebase/firestore";
export default Vue.extend({
  name: "CorregirSimulacro",
  data: () => ({
    loading: true,
    simulacroTitle: null,
    simulationTestId: null,
    success: null,
    message: null,
    corrections: null,
    rankingData: null,
    rankingTable: {
      headers: [
        {
          text: 'Posición',
          value: 'position'
        },
        {
          text: 'Nota',
          value: 'grade'
        },
        {
          text: 'Nombre',
          value: 'display_name'
        },
        {
          text: 'Email',
          value: 'email'
        }
      ]
    }
  }),
  created() {
    this.$store
      .dispatch("tests/getActiveSimulationTestId")
      .then((activeSimulationTestId) => {
        this.simulationTestId = activeSimulationTestId;
        this.getCorrections().then(cs => this.prepareRankingData(cs));
        this.$store
          .dispatch("tests/getExam", activeSimulationTestId)
          .then((test) => {
            this.simulacroTitle = test.title;
            this.loading = false;
          });
      }).catch(error => {this.loading = false});
  },
  methods: {
    prepareRankingData(cs) {
      this.corrections = cs;
      if (this.corrections.length > 0){
      this.rankingData = this.corrections[0].ranking.map(function(item) {
        return {
          position: item.position,
          grade: Math.round(item.grade * 1000) / 1000,
          display_name: item.user.display_name,
          email: item.user.email
        }
      })
    }
    },
    getCorrections() {
        return new Promise((resolve, reject) => {
          firebase
            .firestore()
            .collection("corrections")
            .where("testId", "==", this.simulationTestId)
            .where("status", "==", "success")
            .orderBy("corrected_at", "desc")
            .limit(1)
            .get()
            .then(function(querySnapshot) {
              const result = [];
              querySnapshot.forEach(function(doc) {
                // doc.data() is never undefined for query doc snapshots
                //console.log(doc.id, " => ", doc.data());
                result.push({
                  id: doc.id,
                  ...doc.data()
                });
              });
              resolve(result);
            })
            .catch(error => {
              reject(error);
            });
        });
    },
    async corregir() {
      if (this.simulationTestId) {
        firebase
          .firestore()
          .collection("corrections")
          .add({
            testId: this.simulationTestId
          })
          .then(function(docRef) {
            console.log('Doc added ' + docRef.id)
            alert('El examen se ha mandado a corregir. Esta operación puede tardar unos minutos. Para saber si ha terminado, refresca la página pero no pulses otra vez el botón hasta pasados 5 minutos.')
          })
          .catch(function(error) {
            console.error(error);
          });
      }
    },
  },
});
