




























// TODO: Guardar un timestamp en el test con la última vez que se ha corregido para dar info al usuario
//       de que el simulacro ya se ha corregido.
import Vue from "vue";
import * as firebase from "firebase/app";
import "firebase/firestore";
import { PermissionLevel } from "@/models/permissions/interface";
import { SimulationMode } from "@/models/simulation_mode/interface";

export default Vue.extend({
  name: "ComprobarAccesoSimulacro",
  data: () => ({
    loading: true,
    responses: []
  }),
  async created() {
    const simulations = await this.getSimulations();
    for (let i = 0; i < simulations.length; ++i) {
      const simulation = simulations[i];
        let response = null;
        switch (simulation.simulation_mode) {
          case SimulationMode.OPEN:
            response = 'Tienes acceso. El simulacro es abierto a todos'
            break;
          case SimulationMode.ONLY_PURCHASERS: {
            if (await this.userIsPurchaser(simulation.id)) {
             response = 'Tienes acceso. El simulacro es de pago y hemos registrado tu pago';
            } 
            else response = 'No tienes acceso. El simulacro es de pago y no hemos registrado tu pago con el mail ' + this.$store.state.user.email;
            break;
          }
          case SimulationMode.PURCHASERS_AND_PREMIUM: {
            if (this.userIsPremium()) response = 'Tienes acceso. El simulacro es para alumnos y compradores y tú eres alumno';
            else if (await this.userIsPurchaser(simulation.id)) response = 'Tienes acceso. El simulacro es para alumnos y compradores y hemos registrado tu pago';
            else response = 'No tienes acceso. El simulacro es para alumnos y compradores y no eres alumno ni hemos registrado tu pago con el mail ' + this.$store.state.user.email;
            break;
          }
          case SimulationMode.ONLY_PREMIUM: {
            if (this.userIsPremium()) {
              response = 'Tienes acceso. El simulacro es solo para alumnos.'
            } else response = 'No tienes acceso. El simulacro es solo para alumnos y no tienes una suscripción activa con el mail ' + this.$store.state.user.email;
            break;
          }
        }
        this.responses.push(
          {
            test: {id: simulation.id, name: simulation.title},
            response: response
          }
        )
    }
    this.loading = false;
  },
  methods: {
      userIsPremium() {
        return this.$store.state.user.subscribedSince[PermissionLevel.PREMIUM];
      },
      userIsPurchaser(testId) {
        const userEmail = this.$store.state.user.email;
        return new Promise((resolve, reject) => {
        firebase
          .firestore()
          .collection("resources")
          .doc("restricted_tests")
          .get()
          .then(doc => {
            if (doc.exists) {
              if (doc.get(testId)) {
                const data = doc.data()
                if (data[testId].includes(userEmail)) {
                  resolve(true)
                } else {
                  resolve(false);
                }
              } else {
                resolve(false);
              }
            } else {
              reject("Document restricted_tests does not exist");
            }
          })
          .catch(error => {
            console.log(error);
            resolve(false);
          });
      });
    },
      testIsRestrictedAndUserHasAccess(tests) {
        const userEmail = this.$store.state.user.email;
        return new Promise((resolve, reject) => {
        firebase
          .firestore()
          .collection("resources")
          .doc("restricted_tests")
          .get()
          .then(doc => {
            if (doc.exists) {
              const responses = [];
              tests.forEach(test =>{
                const testId = test.id;
                const testName = test.name;
                if (doc.get(testId)) {
                const data = doc.data()
                if (data[testId].includes(userEmail) || this.$store.state.user.subscribedSince[PermissionLevel.PREMIUM]) {
                  responses.push({test: test, response: 'Tienes acceso'})
                } else {
                  responses.push({test: test, response: 'No tienes acceso ['+ userEmail + ']'})
                }
              } else {
                responses.push({test: test, response: 'Tienes acceso'})
              }
              })
              resolve(responses);
            } else {
              reject("Document restricted_tests does not exist");
            }
          })
          .catch(error => {
            console.log(error);
            resolve(false);
          });
      });
    },
    async prepareSimulations(ss) {
      return new Promise( (resolve, reject) => {
      resolve(ss.map(function(item) {
        return {
          id: item.id,
          name: item.title,
        }
      }))
    })},
    getUpcomingRestrictedSimulationIds() {
      return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("resources")
        .doc("simulation")
        .get()
        .then(doc => {
          if (doc.exists) {
            const UpcomingRestrictedSimulationIds = doc.data().upcoming_restricted_simulations;
            if (UpcomingRestrictedSimulationIds) resolve(UpcomingRestrictedSimulationIds);
            else reject("No hay ningún simulacro definido");
          } else {
            reject("Document does not exist");
          }
        })
        .catch(error => {
          reject(error);
        });
    });
    },
    getSimulations() {
        return new Promise((resolve, reject) => {
          firebase
            .firestore()
            .collection("tests")
            .where("isTimed", "==", true)
            .where("simulation_mode", "in", [SimulationMode.OPEN, 
                                             SimulationMode.ONLY_PURCHASERS, 
                                             SimulationMode.PURCHASERS_AND_PREMIUM, 
                                             SimulationMode.ONLY_PREMIUM])
            .where("endDate", ">", new Date())
            .orderBy("endDate", "desc")
            .get()
            .then(function(querySnapshot) {
              const result = [];
              querySnapshot.forEach(function(doc) {
                result.push({
                  id: doc.id,
                  ...doc.data()
                });
              });
              resolve(result);
            })
            .catch(error => {
              reject(error);
            });
        });
    },
  },
});
