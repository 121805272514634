


































import UserSubmission from "@/models/user_submission/interface";
import Vue from "vue";

export default Vue.extend({
  name: "History",
  data() {
    return {
      headers: [
        {
          text: this.$i18n.t("components/profile/history/headers/name"),
          value: "testName"
        },
        {
          text: this.$i18n.t("components/profile/history/headers/grade"),
          value: "grade"
        },
        {
          text: this.$i18n.t("components/profile/history/headers/date"),
          value: "ended"
        }
      ],
      items: [] as Array<UserSubmission>,
      options: {
        page: 1,
        itemsPerPage: 5
      },
      loading: true,
      numElements: 0
    };
  },
  watch: {
    options: {
      handler() {
        this.setElementsForPage(this.options.page);
      },
      deep: true
    }
  },
  mounted() {
    this.$store
      .dispatch("vm/history/onMounted")
      .then(() => {
        this.numElements = this.$store.state.vm.history.numElements;
        this.setElementsForPage(this.options.page);
      })
      .catch(() => {
        this.showError();
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    showError(): void {
      this.$store.commit("showMessage", {
        text: this.$t("components/profile/history/error/general"),
        color: "error"
      });
    },

    setElementsForPage(page: number) {
      const firstElementIndex =
        this.options.itemsPerPage * (this.options.page - 1);
      const maxNumElementsToShow =
        this.options.itemsPerPage * this.options.page;
      const lastElementIndex = Math.min(maxNumElementsToShow, this.numElements);
      const elementsLoaded = this.$store.state.vm.history.testSubmissions
        .length;

      if (elementsLoaded < lastElementIndex) {
        this.loading = true;
        this.$store
          .dispatch("vm/history/fetchNextSubmissions", 5)
          .then(() => {
            this.items = this.$store.state.vm.history.testSubmissions.slice(
              firstElementIndex,
              lastElementIndex
            );
          })
          .catch(() => {
            this.showError();
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.items = this.$store.state.vm.history.testSubmissions.slice(
          firstElementIndex,
          lastElementIndex
        );
      }
    },

    formatTimestamp(timestamp): string {
      const date = timestamp.toDate();
      return (
        date.getDate() +
        "/" +
        (date.getMonth() + 1) +
        "/" +
        date.getFullYear() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes()
      );
    },

    userSubmissionSelected(submission) {
      this.$router.push({
        name: "result",
        params: {
          testId: submission.testId,
          submissionId: submission.submissionId
        }
      });
    }
  }
});
