




















































































































































































































































import Address from "@/models/address/interface";
import { LogLevels } from "@/models/log/interface";
import User from "@/models/user/interface";
import Vue from "vue";

export default Vue.extend({
  name: "UserDetails",
  data: () => ({
    nameRules: [],
    ui: {
      editable: false,
      valid: false,
      loading: false
    },
    name: null,
    email: null,
    birthDayPicker: {
      date: null,
      menu: false
    },
    address: {
      city: null,
      country: "SPAIN",
      line1: null,
      line2: null,
      postalCode: null,
      state: null
    },
    ccaaKeys: [
      "ANDALUCIA",
      "ARAGON",
      "ASTURIAS",
      "BALEARES",
      "CANARIAS",
      "CANTABRIA",
      "CASTILLA_MANCHA",
      "CASTILLA_LEON",
      "CATALUNYA",
      "EXTREMADURA",
      "GALICIA",
      "RIOJA",
      "MADRID",
      "MURCIA",
      "NAVARRA",
      "PAIS_VASCO",
      "VALENCIA",
      "CEUTA",
      "MELILLA"
    ],
    drivingLicenses: [],
    // drivingLicenses: ['AM', 'A1', 'A2', 'A', 'B', 'B+E', 'C1', 'C1+E', 'C', 'C+E', 'D1', 'D1+E', 'D', 'D+E'],
    nextOpos: "",
    otherAcademies: "",
    pastOpos: ""
  }),
  computed: {
    ccaa: function() {
      return this.ccaaKeys.map(ca => {
        return { text: this.$t("constants/ccaa/" + ca), value: ca };
      });
    }
  },
  watch: {
    "birthDayPicker.menu"(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    }
  },
  mounted() {
    this.readFieldsFromState();
  },
  methods: {
    readFieldsFromState() {
      this.name = this.$store.state.user.username;
      this.email = this.$store.state.user.email;

      this.birthDayPicker.date = this.$store.state.user.user.birthDate
        ?.toISOString()
        .substr(0, 10);
      this.address.city = this.$store.state.user.user.address.city;
      this.address.country = this.$store.state.user.user.address.country;
      this.address.line1 = this.$store.state.user.user.address.line1;
      this.address.line2 = this.$store.state.user.user.address.line2;
      this.address.postalCode = this.$store.state.user.user.address.postalCode;

      this.address.state = this.$store.state.user.user.address.state;

      this.drivingLicenses = this.$store.state.user.user.drivingLicenses;
      this.nextOpos = this.$store.state.user.user.nextOpos;
      this.pastOpos = this.$store.state.user.user.pastOpos;
      this.otherAcademies = this.$store.state.user.user.otherAcademies;
    },
    datepickerSave(date) {
      this.$refs.menu.save(date);
    },

    discardChanges() {
      this.ui.editable = false;
      this.readFieldsFromState();
    },

    saveChanges() {
      this.ui.editable = false;
      this.ui.loading = true;

      const address: Address = {
        city: this.address.city,
        country: this.address.country,
        line1: this.address.line1,
        line2: this.address.line2,
        postalCode: this.address.postalCode,
        state: this.address.state
      };

      let birthDate = null;
      if (this.birthDayPicker.date) {
        birthDate = new Date(Date.parse(this.birthDayPicker.date));
      }

      const user = {
        birthDate: birthDate,
        address: address,
        drivingLicenses: this.drivingLicenses,
        nextOpos: this.nextOpos,
        otherAcademies: this.otherAcademies,
        pastOpos: this.pastOpos
      };

      this.$store
        .dispatch("user/setUser", user)
        .catch(error => {
          this.$store.dispatch("log", {
            level: LogLevels.ERROR,
            message: error
          });
          this.$store.commit("showMessage", {
            text: this.$t("components/profile/user_details/error/cannot_save"),
            color: "error"
          });
        })
        .finally(() => {
          this.readFieldsFromState();
          this.ui.loading = false;
        });
    }
  }
});
